export default function getImageDimensions(file) {
    return new Promise (function (resolved, rejected) {
      try {
        var i = new Image()
        i.onload = function(){
          resolved({w: i.width, h: i.height})
        };
        i.src = file
      } catch (e) {
        rejected({w: 50, h: 50})
      }
    }
  )}